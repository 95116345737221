<template>
  <div class="box">
    <div class="headline">
      软件背景
    </div>
  </div>
</template>

<script>
export default {};
</script>
